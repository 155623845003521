import * as React from "react";



import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";



import * as styles from "../styles/legal.module.scss";


export const Head = () => {
	return <HeadMeta title="Client Relationship Summary" />;
};
const AdvisoryRelationshipSummaryPage = () => {
	return (
		<Frame contained="true">
			<div className={styles.legalWrap}>
				<h1>Form CRS, ADV Part 3: A Summary of Your Advisory Relationship</h1>
				<time className={styles.center}>March 2022</time>
				<h2>Item 1. Introduction</h2>
				<p>
					Cynosure Management, LLC (“Cynosure”), is registered with the U.S.
					Securities and Exchange Commission (“SEC”) as an investment adviser.
					Clients and prospective clients should be aware that brokerage and
					investment advisory services and fees differ, and it is important for
					retail investors to understand the differences. Free and simple tools
					are available to research advisory firms like us at investor.gov/CRS,
					which also provides educational materials about investment advisers
					and investing.
				</p>
				<h2>Item 2. Relationships and Services</h2>
				<h3>What investment services and advice can you provide me?</h3>
				<p>
					<strong>Services:</strong> We provide Alternatives Investment
					Offerings, as well as Wealth Management Services. This document
					focuses on Wealth Management Services. Our Wealth Management Services
					involve developing comprehensive, customized investment programs based
					on an analysis of various factors, such as your investment goals, tax
					position, diversification requirements, other assets held, social
					concerns, risk tolerance, etc. After completing this analysis, we will
					construct a portfolio for you by allocating your assets among various
					investment strategies (“Strategies”) through investments in separate
					accounts with sub- advisors and/or pooled investment vehicles. Your
					investment program is monitored on an ongoing basis, reviewed with you
					at least annually, and is adjusted from time to time in response to
					changing market conditions, your circumstances or other factors. Most
					of the Strategies we use for our Wealth Management clients will focus
					on investments in publicly traded securities, as well as private
					equity and other alternative investment vehicles, including pooled
					investment vehicles (“PIVs”) we manage. Strategies fall within four
					key allocation categories identified as Stable Value, Diversified
					Income, Public Equity, and Private Equity. When you grant us
					discretionary authority, you provide us the authority to determine the
					investments to buy or sell in your account on an ongoing basis. In
					cases where we have non-discretionary trading authority, you are
					responsible for making your own trading decisions. Lastly, as
					investment adviser to pooled investment vehicles, we participate in
					the management, monitoring and disposition of investments for those
					funds.
				</p>
				<p>
					<strong>Requirements to open and maintain an account:</strong> We
					prefer to establish relationships of at least $10,000,000 in order to
					act as investment manager. However, we may waive this minimum in its
					sole discretion.
				</p>
				<p>
					<strong>ADDITIONAL INFORMATION:</strong> Additional information can be
					found in our Form ADV, Part 2A, Items 4, 7, 8, 12, & 13 available at:{" "}
					<a
						href="https://adviserinfo.sec.gov/firm/summary/281399"
						target="_blank"
						rel="noreferrer">
						https://adviserinfo.sec.gov/firm/summary/281399
					</a>{" "}
					(click on “Part 2 Brochures”).
				</p>
				<p className="aside">
					<strong>Conversation Starters:</strong> Given my financial situation,
					should I choose an investment advisory service? Why or why not? How
					will you choose investments to recommend to me? What is your relevant
					experience, including your licenses, education, and other
					qualifications? What do these qualifications mean?
				</p>
				<h2>Item 3: Fees, Costs, Conflicts and Standard of Conduct</h2>
				<h3>What fees will I pay?</h3>
				<p>
					<strong>Principal Fees and Costs:</strong> We are generally
					compensated for our investment advisory services as a percentage of
					the value of your accounts we are managing (charged quarterly in
					arrears). The annual management fee charged for this service will be
					negotiated with you, with 0.9% being the maximum management fee that
					will be charged to you. In addition to the advisory fees described
					above, you will be responsible for paying certain fees and other
					expenses charged in respect of the investment Strategies to which we
					allocate your assets. Fees you pay us for investment advisory services
					are separate and distinct from other fees and expenses charged to your
					accounts and investments, such as brokerage, custodial, trading,
					account maintenance, costs of the investment and related fees.
					Additionally, mutual, exchange traded and money market funds contain
					internal expenses which are applied before your position is valued.
					The more assets there are in your advisory account, the more you will
					pay in fees, and we may therefore have an incentive to encourage you
					to increase the assets in your account. You will pay fees and costs
					whether you make or lose money on your investments. Fees and costs
					will reduce any amount of money you make on your investments over
					time. Please make sure you understand what fees and costs you are
					paying.
				</p>
				<p>
					<strong>ADDITIONAL INFORMATION:</strong> For more information, please
					visit and see Items 5-6 & 12 in Form ADV, Part 2A, available at{" "}
					<a
						href="https://adviserinfo.sec.gov/firm/summary/281399"
						target="_blank"
						rel="noreferrer">
						https://adviserinfo.sec.gov/firm/summary/281399
					</a>{" "}
					(click on “Part 2 Brochures”).
				</p>
				<p className="aside">
					<strong>Conversation Starter:</strong> Help me understand how these
					fees and costs might affect my investments. If I give you $10,000 to
					invest, how much will go to fees and costs, and how much will be
					invested for me?
				</p>
				<h3>Standard of Conduct/Conflicts of Interest</h3>
				<p>
					<strong>
						What are your legal obligations to me when acting as my investment
						adviser? How else does your firm make money and what conflicts of
						interest do you have?
					</strong>
				</p>
				<p>
					<strong>When we act as your investment adviser,</strong> we have to
					act in your best interest and not put our interest ahead of yours. At
					the same time, the way we make money creates some conflicts with your
					interests. You should understand and ask us about these conflicts
					because they can affect the investment advice we provide you. Here are
					some examples to help you understand what this means. We are the
					manager of two pooled investment vehicles which jointly own a minority
					interest in Avalon Investment and Advisory, LLC (“Avalon”), a
					registered investment advisor. Avalon may serve as a sub-advisor for
					your accounts. We may allocate some of your assets, where appropriate,
					to investment strategies managed by Avalon. This creates a conflict of
					interest as both entities receive management fees on the assets
					allocated to strategies Avalon manages. Additionally, we serve as a
					sub-advisor to Avalon in the administration of certain alternative
					investments. This creates a conflict of interest since both entities
					receive management fees on these assets. We also charge fees for
					allocating assets to certain Cynosure strategies which creates a
					conflict of interest as it creates an incentive to allocate more
					assets to those strategies over others. These conflicts are mitigated
					by closely following your established Investment Policy which is
					re-evaluated at least annually.
				</p>
				<p>
					<strong>Performance Fees.</strong> If it aligns with your Investment
					Policy and you meet the financial requirements to invest, we may
					recommend that you invest in private funds, direct private investments
					or co-investment opportunities managed by us, which charge
					performance-based fees. This creates an incentive for us to take risks
					in managing such vehicles we might not otherwise take, solely to earn
					a fee. It also creates an incentive to favor allocating assets to such
					Strategies.
				</p>
				<p>
					We are fiduciaries to you. That means we are required to act in your
					best interest throughout our entire advisory relationship.
				</p>
				<p className="aside">
					<strong>Conversation Starter:</strong> How might your conflicts of
					interest affect me, and how will you address them?
				</p>
				<p>
					<strong>ADDITIONAL INFORMATION:</strong> For more information on
					conflicts of interest, see Items 4, 9, 10, 11, 12 and 14 of our Form
					ADV, Part 2, available at{" "}
					<a
						href="https://adviserinfo.sec.gov/firm/summary/281399"
						target="_blank"
						rel="noreferrer">
						https://adviserinfo.sec.gov/firm/summary/281399
					</a>{" "}
					(click on “Part 2 Brochures”).
				</p>
				<h3>How do your financial professionals make money?</h3>
				<p>
					Our financial professionals are compensated through a negotiated base
					salary. They are eligible for cash bonuses based on the overall
					performance of the firm, as determined by senior management.
				</p>
				<h2>Item 4: Disciplinary History</h2>
				<h3>
					Do you or your financial professionals have legal or disciplinary
					history?
				</h3>
				<p>
					No. For more information, please refer to Form ADV, Part 2A, Item 9
					at:{" "}
					<a
						href="https://adviserinfo.sec.gov/firm/summary/281399"
						target="_blank"
						rel="noreferrer">
						https://adviserinfo.sec.gov/firm/summary/281399
					</a>{" "}
					(click on “Part 2 Brochures”), or you can visit Investor.gov/CRS for
					free and simple search tool to research us and our financial
					professionals.
				</p>
				<p>
					<strong>Conversation Starter:</strong> As a financial professional, do
					you have any disciplinary history? For what type of conduct?
				</p>
				<h2>Item 5: Additional Information</h2>
				<p>
					If you have any questions about our investment advisory services or if
					you wish to request a copy of the relationship summary, please contact
					us at 801-521-3100. Additional information about us is also available
					on the SEC’s website at investor.gov/CRS.
				</p>
				<p>
					<strong>Conversation Starter:</strong> Who is my primary contact
					person? Is he or she a representative of an investment adviser or
					broker-dealer? Who can I talk to if I have concerns about how this
					person is treating me?
				</p>
			</div>
		</Frame>
	);
};

export default AdvisoryRelationshipSummaryPage;
